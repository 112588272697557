<div class="content">
  <div class="container" [class.mobile-container]="utilsService.isMobile">
    <app-a-propos></app-a-propos>
    <app-experiences> </app-experiences>
    <app-projets></app-projets>
    <app-competences></app-competences>
    <app-certifications></app-certifications>
    <app-historique></app-historique>
  </div>
</div>
